import React, { Fragment } from "react";
import PivotTableUI from 'react-pivottable/PivotTableUI';

export default class Pivot extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props, data: null }
    this.data = props.data
  }

  render(){
    return (
      <PivotTableUI
        onChange={s => this.setState(s)}
        {...this.state}
        data={this.data}
      />
    );
  }
}

